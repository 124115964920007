import React from "react"
import Container from "react-bootstrap/Container"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

const OurStory = () => {
  return (
    <Layout class="section-our-story">
      <Seo title="Our Story" />
      <section class="section-our-story__about">
        <Container class="container">
          <div class="row">
            <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
              <h2 class="section-our-story__title">Our Story</h2>
              <p class="section-our-story__about__text">
                <span style={{ fontWeight: 700 }}>Adriana Beach</span> is
                a&nbsp;
                <em>family-run accommodation</em>. The owners focus on the
                little details and have been improving the facilities by
                renovating and redesigning the premises every year. Every item
                is carefully picked and placed in space in order to create a
                comfortable, relaxation-inducing environment.
              </p>
              <p class="section-our-story__about__text">
                The accommodation offers different types of apartments and
                suites, all with a mesmerizing view over the Aegean sea.
                Moreover, <span style={{ fontWeight: 700 }}>A.bar</span>{" "}
                offers&nbsp;
                <em>delicious snacks and cocktails</em> to our guests, either at
                the sofa lounges or our thick-mattressed sunbeds.
              </p>
            </div>
            <div class="col-12 col-lg-6">
              <StaticImage
                src="../assets/images/story-1.jpg"
                alt="Placeholder"
                layout="fullWidth"
                placeholder="blurred"
              />
            </div>
          </div>
        </Container>
      </section>
      <section class="section-our-story__location">
        <Container class="container">
          <div class="row">
            <div class="col-12 col-lg-6 order-lg-last d-flex align-items-center">
              <p class="section-our-story__location__text">
                <span style={{ fontWeight: 700 }}>Adriana Beach</span> is
                located right in front (5m) of the beach, and the village's
                restaurants and bars are only 150m away! We provide private
                parking in our space.
                <br />
                <br />
                <span style={{ fontWeight: 700 }}>Thessaloniki</span> is 100km
                away with a plethora of shopping options, restaurants, and bars.
                Macedonia Airport (SKG) is also located there, and we provide
                transfer services upon request.
              </p>
            </div>
            <div class="col-12 col-lg-6">
              <StaticImage
                src="../assets/images/story-2.jpg"
                alt="Placeholder"
                layout="fullWidth"
                placeholder="blurred"
              />
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default OurStory
